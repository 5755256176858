import "./PlayGame.scss";
import locale from "../../assets/locale.json";
import React from "react";
import { ContentProps, EnumContentModal } from "../../models/content";
import Button from "../Button/Button";
import youWinImg from "../../assets/images/you-win.webp";
import cardLottie from "../../assets/lotties/CardLottie.json"
import Lottie from "lottie-react";
import { PrizeAwardProps } from "../../models/prizeAward";
import { useParseText } from "../../utils/useParseText";
import { track } from "../../utils/Tracking";

interface PlayGameProps extends ContentProps {
    result?: PrizeAwardProps;
    isLoop?: boolean
}

const PlayGame: React.FC<PlayGameProps> = ({
  isWinVariant = false,
  onAnimationComplete,
  changeContent = () => {},
  isLoop = false,
  result,
}) => {
  const { text, altImg, youWin } = locale.playGame;
  const { parseText } = useParseText();

  if (isWinVariant) {
    track({
      touchpoint: 'WEB',
      alias_page: 'alias_payandwin_hai_vinto',
      section: 'Gioca',
      info_prodotto_servizio_motivo: youWin.title,
      title_page: 'Hai Vinto',
      site_section: 'Pay and Win'
    });
  }

  return (
    <div className="pw-play-game-container">
      {!isWinVariant ? (
        /* EnumContentModal.PLAY_GAME */
        <div className="pw-play-game-content">
          <Lottie
            animationData={cardLottie}
            className="pw-play-game-lottie"
            onComplete={onAnimationComplete}
            loop={isLoop}
          />
          <p className="pw-play-game-text paragraph-play-game">{text}</p>
        </div>
      ) : (
        /* EnumContentModal.YOU_WIN */
        <div className="pw-you-win-content">
          <h3 className="pw-you-win-logo title-four-bold" dangerouslySetInnerHTML={parseText(youWin.logo)} />
          <div className="pw-you-win-img-container">
            <img src={youWinImg} alt={altImg} className="pw-you-win-img" />
            <h2 className="title-one-semi-bold">{youWin.title}</h2>
          </div>
          <Button
            className="pw-you-win-button"
            theme="white"
            label={youWin.buttonLabel}
            onClick={() =>
              changeContent(
               result && result.prize.type === "digital-voucher"
                  ? EnumContentModal.GAME_WIN_DIGITAL_AWARD
                  : EnumContentModal.GAME_WIN_EXPERIENCE_AWARD
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default PlayGame;
