import React from "react";
import "./ModalFinishedPlays.scss";
import SimpleContent from "../SimpleContent/SimpleContent";
import locale from "../../assets/locale.json";
import finished_plays_image from "../../assets/images/finished_plays.webp";
import Modal from "../Modal/Modal";
import { ModalActionProps } from "../../models/modalAction";
import { track } from "../../utils/Tracking";

const ModalFinishedPlays: React.FC<ModalActionProps> = ({ isOpen, onClose }) => {
    const { finished_plays: { altImg, title, description, buttonLabel } } = locale;

    track({
        touchpoint: 'WEB',
        alias_page: 'alias_payandwin_assenza_giocate',
        section: 'KO Page',
        title_page: 'Assenza Giocate Disponibili',
        site_section: 'Pay and Win',
    })

    return (
        <div className="pw-finished-plays-modal">
            <Modal isOpen={isOpen} onClose={onClose}>
                <div className="pw-finished-plays-container">
                    <SimpleContent
                        image={{ alt: altImg, src: finished_plays_image }}
                        title={title}
                        description={() =>
                            <p className="pw-simple-content-description paragraph-semi-bold-small">
                                {description}
                            </p>
                        }
                        confirmationButton={{
                            theme: "blue",
                            label: buttonLabel,
                            onClick: onClose,
                            className: "pw-button-finished-plays"
                        }}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default ModalFinishedPlays