import React, { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "../Modal/Modal";
import locale from "../../assets/locale.json";
import PreRegistration from "../PreRegistration/PreRegistration";
import ThankYouPage from "../ThankYouPage/ThankYouPage";
import confirmationImage from "../../assets/images/subscription-confirmation.webp";
import SimpleContent from "../SimpleContent/SimpleContent";
import { EnumIcon } from "../../models/enumIcon";
import { ModalActionProps } from "../../models/modalAction";
import "./ModalSubscribe.scss"
import { EnumContentModal } from "../../models/content";
import ModalPlayGame from "../ModalPlayGame/ModalPlayGame";
import useModal from "../../utils/useModalUtils";

const ModalSubscribe: React.FC<ModalActionProps> = ({ isOpen, onClose }) => {
  const [content, setContent] = useState<EnumContentModal>(EnumContentModal.PRE_REGISTRATION);
  const { ModalType, isModalOpen, openModal, closeModal } = useModal();

  const changeContent = (content: EnumContentModal) => {
    setContent(content);
  };

  const handleClose = useCallback(() => {
    if (!isModalOpen(ModalType.PLAY_GAME)) {
      window.dispatchEvent(new Event("userRegistered"));
    }
    if(content === EnumContentModal.PRE_REGISTRATION) {
      return changeContent(EnumContentModal.CONFIRMATION_MESSAGE)
    }
    onClose();
  }, [onClose, isModalOpen, ModalType, content]);

  const openGame = useCallback(() => {
    onClose();
    openModal(ModalType.PLAY_GAME);
  }, [ModalType, onClose, openModal]);

  useEffect(() => {
    if (!isOpen) {
      setContent(EnumContentModal.PRE_REGISTRATION);
    }
  }, [isOpen]);

  const renderContent = useMemo(() => {
    switch (content) {
      case EnumContentModal.PRE_REGISTRATION:
        return <PreRegistration changeContent={changeContent} />;

      case EnumContentModal.THANK_YOU_PAGE:
        return <ThankYouPage openGame={openGame} onClose={handleClose} />;

      case EnumContentModal.CONFIRMATION_MESSAGE:
        return (
          <div className="pw-confirmation-message-container">
            <SimpleContent
              image={{
                alt: locale.subscriptionConfirmationModal.altImg,
                src: confirmationImage,
              }}
              title={locale.subscriptionConfirmationModal.title}
              description={() => {
                return (
                  <p className="pw-simple-content-description">
                    {locale.subscriptionConfirmationModal.description}
                  </p>
                );
              }}
              confirmationButton={{
                theme: "blue",
                label:
                  locale.subscriptionConfirmationModal.button.confirmationLabel,
                onClick: onClose,
                className: "pw-simple-content-button-confirmation-message",
              }}
              cancellationButton={{
                theme: "blue underline",
                label:
                  locale.subscriptionConfirmationModal.button.cancellationLabel,
                icon: EnumIcon.arrowLeft,
                onClick: () => changeContent(EnumContentModal.PRE_REGISTRATION)
              }}
            />
          </div>
        );
      default:
        return <PreRegistration changeContent={changeContent} />;
    }
  }, [content, handleClose, onClose, openGame]);

  const fullWidthContents = [EnumContentModal.PRE_REGISTRATION, EnumContentModal.THANK_YOU_PAGE];
  const isFullWidth = fullWidthContents.includes(content);

  const closeButtonClassName =
    content === EnumContentModal.THANK_YOU_PAGE
      ? "pw-thank-you-page-close-button"
      : content === EnumContentModal.PRE_REGISTRATION
        ? "pw-pre-registration-close-button"
        : "";

  const modalClassName = 
    content === EnumContentModal.CONFIRMATION_MESSAGE 
      ? "pw-confirmation-message-height" 
      : content === EnumContentModal.PRE_REGISTRATION
        ? "pw-pre-registration-height"
        : "";

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        fullWidth={isFullWidth}
        closeButtonTheme={closeButtonClassName}
        className={modalClassName}
      >
        {renderContent}
      </Modal>
      <ModalPlayGame isOpen={isModalOpen(ModalType.PLAY_GAME)} onClose={() => closeModal(ModalType.CLOSE)} />
    </>
  );
};

export default ModalSubscribe;
