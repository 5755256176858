import locale from "../../assets/locale.json";
import "./NavBar.scss";
import { Button } from "..";
import { Link } from "react-router-dom";
import { useComponentVisible } from "../../utils/useDeviceSize";
import logo from "../../assets/images/posteitaliane.svg";
import { useEffect, useRef } from "react";
import { EnumIcon } from "../../models/enumIcon";
import useModalUtils from "../../utils/useModalUtils";
import ModalSubscribe from "../ModalSubscribe/ModalSubscribe";
import ModalLogout from "../ModalLogout/ModalLogout";
import { useAuthContext } from "../../utils/AuthContext";
import { useUser } from "../../utils/useUserExist";
import Modal from "../Modal/Modal";
import CannotParticipate from "../CannotParticipate/CannotParticipate";

// https://medium.com/@navneetskahlon/react-navlink-vs-link-comparing-use-cases-and-examples-af438a63319c
/* In this project we would not use the "NavLink" tag (which inserts a class for the page corrected) because
 * changing page in this case landing on the FQA page.
 * The help links will not be visible.
 * 
 * To introduce React router I followed this reference.
 * https://luqmanshaban.medium.com/react-router-a-step-by-step-guide-4c5ec964d2e9
*/

const NavBar = () => {
    const user = useUser();
    const windowScroll = useRef<number>(0);
    const { isMobile } = useComponentVisible();
    const {
        exit,
        support,
        logo: { alt, link },
        popupNav: { title, label }
    } = locale.navBar;
    const { openModal, closeModal, isModalOpen, ModalType } = useModalUtils();
    const { responseError } = useAuthContext();
    const isNotCourtesyLanding = window.location.pathname !== "/courtesy-landing";
    const isPathSupport = window.location.pathname === "/support";

    useEffect(() => {
        const secondMenu: HTMLDivElement | null = document.querySelector("#pw-menu-header");
        window.addEventListener("scroll", () => {
            const title = document.querySelector("#title-hero") as HTMLElement;
            if (secondMenu && title) {
                const directionScroll = title?.getBoundingClientRect().bottom <= 0;
                secondMenu?.classList[directionScroll ? "add" : "remove"]("show");
            }
            windowScroll.current = window.scrollY;
        });

        return () => {
            window.removeEventListener("scroll", () => { });
        };
    }, []);

    return (
        <div className="pw-container-navbar">
            {/* Main Navbar */}
            <nav className="navbar-main pw-container">
                <ul className="wrapper-item">
                    <li className="item content-logo">
                        <Link to={link} target="_blank" className="pw-logo">
                            <img src={logo} className="pw-image" alt={alt} />
                        </Link>
                    </li>
                    {isNotCourtesyLanding &&
                        <>
                            {!isPathSupport &&
                                <li className="item">
                                    <Button
                                        ariaLabel={isMobile ? support.label : ""}
                                        ariaHidden={isMobile}
                                        theme="blue"
                                        url={support.url}
                                        icon={EnumIcon.help}
                                        className="pw-link button-icon-menu"
                                        label={support.label}
                                    />
                                </li>
                            }
                            <li className="item">
                                <Button
                                    className="pw-link button-icon-menu"
                                    onClick={() => openModal(ModalType.LOGOUT)}
                                    ariaLabel={isMobile ? exit.label : ""}
                                    ariaHidden={isMobile}
                                    theme="blue"
                                    icon={EnumIcon.close}
                                    label={exit.label}
                                />
                            </li>
                        </>
                    }
                </ul>
            </nav>
            {/* Pop-up Navbar */}
            {!user &&
                isNotCourtesyLanding &&
                <div className="navbar-popup" id="pw-menu-header">
                    <div className="navbar-popup-wrapper pw-container">
                        <p className="navbar-popup-title paragraph-bold-xs">
                            {title}
                        </p>
                        <Button
                            theme="blue"
                            label={label}
                            onClick={() => responseError === 403 ? openModal(ModalType.CANNOT_PARTICIPATE) : openModal(ModalType.SUBSCRIBE)}
                            small
                            type="button"
                        />
                    </div>
                </div>
            }
            <>
                <ModalSubscribe isOpen={isModalOpen(ModalType.SUBSCRIBE)} onClose={() => closeModal(ModalType.CLOSE)} />
                <ModalLogout isOpen={isModalOpen(ModalType.LOGOUT)} onClose={() => closeModal(ModalType.CLOSE)} />
                <Modal
                    isOpen={isModalOpen(ModalType.CANNOT_PARTICIPATE)}
                    onClose={() => closeModal(ModalType.CLOSE)}
                    isCannotPartecipateContent
                >
                    <CannotParticipate onClose={() => closeModal(ModalType.CLOSE)}/>
                </Modal>
            </>
        </div>
    );
};

export default NavBar;