import "./Subscribe.scss";
import locale from "../../assets/locale.json";
import { useComponentVisible } from "../../utils/useDeviceSize";
import { useParseText } from "../../utils/useParseText";
import airplaneMobile from "../../assets/images/airplane-mobile.webp";
import airplaneDesktop from "../../assets/images/airplane-desktop.webp";
import suitcase from "../../assets/images/suitcase.webp";
import leftLineBottom from "../../assets/images/left-line-low.webp";
import rightLineTop from "../../assets/images/right-line-top.webp";
import Button from "../Button/Button";
import useModalUtils from "../../utils/useModalUtils";
import Modal from "../Modal/Modal";
import CannotParticipate from "../CannotParticipate/CannotParticipate";
import { useAuthContext } from "../../utils/AuthContext";
import { track } from "../../utils/Tracking";

interface SubscribeProps {
  showModal?: (value: boolean) => void;
}

const Subscribe: React.FC<SubscribeProps> = ({ showModal }) => {
  const { parseText } = useParseText();
  const { isMobile } = useComponentVisible();
  const titleContent = parseText(locale.subscribe.title);
  const { openModal, closeModal, isModalOpen, ModalType } = useModalUtils();
  const { responseError } = useAuthContext();

  track({
    touchpoint: 'WEB',
    alias_page: 'alias_payandwin_modale_chiusura',
    section: 'Iscrizione',
    title_page: 'Modale Chiusura Iscrizione',
    site_section: 'Pay and Win'
  });

  return (
    <>
      <section className="pw-subscribe-section">
        {isMobile ? (
          <>
            <img
              className="pw-image-airplane"
              src={airplaneMobile}
              alt={locale.subscribe.alt.airplane}
            />
            <img
              className="pw-image-left-line-low"
              src={leftLineBottom}
              alt={locale.subscribe.alt.illustration}
            />
            <img
              className="pw-image-right-line-top"
              src={rightLineTop}
              alt={locale.subscribe.alt.illustration}
            />
          </>
        ) : (
          <>
            <img
              className="pw-image-suitcase"
              src={suitcase}
              alt={locale.subscribe.alt.suitcase}
            />
            <img
              className="pw-image-airplane"
              src={airplaneDesktop}
              alt={locale.subscribe.alt.airplane}
            />
          </>
        )}
        <div className="pw-container">
          <div className="row wrapper">
            <div className="col-10 col-sm-7">
              <h1
                className="pw-subscribe-title pw-title-h2 title-two-regular"
                dangerouslySetInnerHTML={titleContent}
              />
              <Button
                label={locale.subscribe.subscribe_button}
                theme="blue"
                onClick={() => responseError === 403 ? openModal(ModalType.CANNOT_PARTICIPATE) : showModal && showModal(true)}
              />
            </div>
          </div>
        </div>
      </section>
      <Modal
        isOpen={isModalOpen(ModalType.CANNOT_PARTICIPATE)}
        onClose={() => closeModal(ModalType.CLOSE)}
        isCannotPartecipateContent
      >
        <CannotParticipate onClose={() => closeModal(ModalType.CLOSE)}/>
      </Modal>
    </>
  );
}

export default Subscribe;