
import { Outlet, Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import { Support } from "../pages/Support";
import { CourtesyLanding } from "../pages/CourtesyLanding";
import ExperienceAwardLanding from "../pages/ExperienceAwardLanding";
import DigitalAwardLanding from "../pages/DigitalAwardLanding";
import { useAuthContext } from "../utils/AuthContext";
import { useUser } from "../utils/useUserExist";
import { PageNotFound } from "../pages/PageNotFound";

/**
 * AppRouter component that defines the application's routing.
 * 
 * This component uses `react-router-dom` to set up the routes for the application.
 * 
 * @component
 * @example
 * // Usage in a parent component:
 * // <AppRouter />
 * 
 * @returns {JSX.Element} The JSX element containing the routes.
 */

const AppRouter = (): JSX.Element => {
  const { isAuth, responseError } = useAuthContext();
  const user = useUser();

  if (responseError === 500) {
    return <CourtesyLanding />;
  }

  return (
    <Routes>
      <Route path="/" element={<Outlet />} >
        <Route path="/" element={<Home />} />
        <Route path="/support" element={<Support />} />
        <Route path="/experience/:id" element={<ExperienceAwardLanding />} />
        <Route path="/digital-award-landing/:id" element={<DigitalAwardLanding />} />
        {!(isAuth || user) && <Route path="/courtesy-landing" element={<CourtesyLanding />} />}
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  )
}

export default AppRouter